import React from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import ErrorSVG from "../images/errorPage.svg";
import Button from "../components/atoms/button";
import Footer from "../components/homePage/footer";

const Container = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   gap: 24px;
   height: 100vh;
   margin: var(--screen-margin);
`;

const NotFoundPage = () => {
   return (
      <Layout>
         <Container>
            <ErrorSVG />
            <h2>Oh! Nothing to see here...</h2>
            <Button content="Return to homepage" url="/" />
         </Container>
         <Footer />
      </Layout>
   );
};

export default NotFoundPage;
